import { create } from 'zustand';

type functionaryExcelState = {
  data: any;
  setfunctionaryExcelData: (data: boolean) => void;
};

export const useFunctionaryExcelDataStore = create<functionaryExcelState>(
  (set, get) => ({
    data: {},
    setfunctionaryExcelData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }),
);

type functionaryAddState = {
  data: any;
  setfunctionaryAddData: (data: boolean) => void;
};

export const useFunctionaryAddDataStore = create<functionaryAddState>(
  (set, get) => ({
    data: {},
    setfunctionaryAddData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }),
);
type functionaryOpenModalState = {
  data: any;
  setfunctionaryOpenModalData: (data: boolean) => void;
};

export const useFunctionaryOpenModalDataStore =
  create<functionaryOpenModalState>((set, get) => ({
    data: {},
    setfunctionaryOpenModalData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }));
type functionaryOpenFormState = {
  data: any;
  setfunctionaryOpenFormData: (data: boolean) => void;
};

export const useFunctionaryOpenFormDataStore = create<functionaryOpenFormState>(
  (set, get) => ({
    data: {},
    setfunctionaryOpenFormData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }),
);
