import { ReactElement, useEffect, useState } from 'react';
import { Input } from '../../../Input';
import { Select } from '../../../Select';
import { Button } from '../../../Button';
import Modal from 'react-modal';
import { useFieldArray } from 'react-hook-form';
import { useHireBussinessPlanForm } from '../../../../contexts/HirePlanBussinessFormProvider';
import { useRouteLoaderData } from 'react-router-dom';
import './functionaryForm.css';
import { SaveIcon } from '../../../icons/SaveIcon';
import ImportExcelFunctionary from '../../../../services/importExcel';
import { PlusIcon } from '../../../icons/PlusIcon';
import {
  dependenteSchema,
  Funcionario,
  Dependente,
  funcionarioSchema,
} from '../data/schema/functionarysSchema';
import {
  changeCivilStatus,
  changeGender,
  changeIssuingAgency,
  changeUF,
} from '../../../../utils/ChangeArrays';
import { validateCEP } from '../../../../utils/ValidateCEP';
import { DependentCard } from '../DependentsForm/DependentCard';
import { DependentForm } from '../DependentsForm/DependentForm';
import { useDependentCountStore } from '../../../../stores/useDependentCountStore';
import { z } from 'zod';
import {
  useFunctionaryAddDataStore,
  useFunctionaryExcelDataStore,
  useFunctionaryOpenFormDataStore,
  useFunctionaryOpenModalDataStore,
} from '../../../../stores/useFunctionaryStore';
import { useVidaCountStore } from '../../../../stores/useQuantidadeVidas';

function validateDependent(obj: any): boolean {
  try {
    dependenteSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

Modal.setAppElement('#root');

interface FunctionaryFormProps {
  id: number | null;
}

export function FunctionaryForm({ id }: FunctionaryFormProps): ReactElement {
  const {
    register,
    handleSubmit,
    goToNextForm,
    onSubmit,
    errors,
    watch,
    setValue,
    control,
    funcionario,
    watchFunc,
    setFuncValue,
    errorsFunc,
  } = useHireBussinessPlanForm();
  const initialPlanData: any = useRouteLoaderData('token-empresa');
  const addFuncionary = useFunctionaryAddDataStore(x => x.data);
  const setAddFuncionary = useFunctionaryAddDataStore(
    x => x.setfunctionaryAddData,
  );
  const FuncionaryOpenModal = useFunctionaryOpenModalDataStore(x => x.data);
  const setFuncionaryOpenModal = useFunctionaryOpenModalDataStore(
    x => x.setfunctionaryOpenModalData,
  );
  //const addExcel = useFunctionaryExcelDataStore(x => x.data);
  const setAddExcel = useFunctionaryExcelDataStore(
    x => x.setfunctionaryExcelData,
  );
  const ind = id > 0 ? id : 0;

  const [isDependentFormActive, setIsDependentFormActive] = useState(false);

  const setCountVida = useVidaCountStore(x => x.setVidaCount);

  const openForm = useFunctionaryOpenFormDataStore(x => x.data);
  const setOpenForm = useFunctionaryOpenFormDataStore(
    x => x.setfunctionaryOpenFormData,
  );

  function activateDependentForm() {
    setIsDependentFormActive(true);
  }
  const funcionarioData = watchFunc();
  function closeDependentForm() {
    setIsDependentFormActive(false);
  }
  const CEP = watchFunc('funcionario.address.cep');
  const CPF = watchFunc('funcionario.cpf');
  const city = watchFunc('funcionario.address.city');
  const neighborhood = watchFunc('funcionario.address.neighborhood');
  const street = watchFunc('funcionario.address.street');

  const dependentCount = useDependentCountStore(state => state.dependentCount);

  const dependents = watch(`user.funcionarios.${id}.dependentes`);

  function setOnlyValidatedDependents() {
    setValue(
      `user.funcionarios.${id}.dependentes`,
      dependents?.filter(dependent => validateDependent(dependent)),
    );
  }

  const getCEPDetails = async (e: string) => {
    const CEPInformations = await fetch(
      `${process.env.REACT_APP_VIACEP}${e}/json/`,
    ).then(response => response.json());

    const UF = initialPlanData.listaUFS.find(
      x => x.sigla == CEPInformations.uf,
    ).Id;
    setFuncValue('funcionario.address.city', CEPInformations.localidade);
    setFuncValue('funcionario.address.neighborhood', CEPInformations.bairro);
    setFuncValue('funcionario.address.street', CEPInformations.logradouro);
    setFuncValue('funcionario.address.uf', String(UF));

    return CEPInformations;
  };
  changeUF(initialPlanData.listaUFS);
  changeGender(initialPlanData.listaSexos);
  changeIssuingAgency(initialPlanData.listaOrgaosExpedidor);
  changeCivilStatus(initialPlanData.listaEstadosCivil);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(initialPlanData);

  const openModal = () => {
    setFuncionaryOpenModal(true);
    setOpenForm(false);
  };

  const closeModal = () => {
    setFuncionaryOpenModal(false);
    setAddExcel(true);
    setAddFuncionary(true);
    setOpenForm(false);
  };

  const addFuncionario = () => {
    setValue(`user.funcionarios.${id}`, funcionarioData.funcionario);
    const funcionarios = watch('user.funcionarios');
    const countdependentes = funcionarios.reduce((count, funcionario) => {
      return (
        count + (funcionario.dependentes ? funcionario.dependentes.length : 0)
      );
    }, 0);
    const count = funcionarios.length + countdependentes;
    setCountVida(count);
    setAddFuncionary(true);
    setAddExcel(true);
    setOpenForm(false);
  };
  const closeFuncionario = () => {
    setAddFuncionary(true);
    setAddExcel(true);
    setOpenForm(false);
  };
  useEffect(() => {
    if (CEP && validateCEP.test(CEP)) {
      getCEPDetails(CEP);
    }
  }, [CEP]);

  useEffect(() => {
    setAddFuncionary(true);
    setAddExcel(true);
    setFuncionaryOpenModal(false);
  }, []);

  const addFuncionarioclick = () => {
    setFuncValue('funcionario', {});
    setOpenForm(true);
    setAddFuncionary(false);
    setAddExcel(false);
  };

  return (
    <div>
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
        Funcionários
      </h2>
      <div className="w-full min-[748px]:mt-16 mt-24 relative flex min-[748px]:justify-end justify-center gap-6">
        <Button
          type="button"
          text="Adicionar Funcionário"
          onClick={openModal}
        />
      </div>

      <Modal
        isOpen={FuncionaryOpenModal}
        onRequestClose={closeModal}
        contentLabel="Formulário de Funcionário"
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
          Dados Funcionário
        </h2>
        <ImportExcelFunctionary />
        {openForm && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-6">
              <Input
                label="Nome completo"
                placeholder="Nome completo"
                errorMessage={errorsFunc.funcionario?.nome?.message}
                {...funcionario('funcionario.nome')}
              />
              <Input
                label="CPF"
                placeholder="CPF"
                activateMask
                mask="999.999.999-99"
                errorMessage={errorsFunc.funcionario?.cpf?.message}
                {...funcionario('funcionario.cpf')}
              />
              <Input
                activateMask
                mask="99/99/9999"
                label="Data de nascimento"
                placeholder="Data de nascimento"
                errorMessage={errorsFunc.funcionario?.dtNascimento?.message}
                {...funcionario('funcionario.dtNascimento')}
              />
              <Input
                label="Nome da mãe"
                placeholder="Nome da mãe"
                errorMessage={errorsFunc.funcionario?.motherName?.message}
                {...funcionario('funcionario.motherName')}
              />
              <Input
                label="E-mail"
                placeholder="E-mail"
                errorMessage={errorsFunc.funcionario?.email?.message}
                {...funcionario('funcionario.email')}
              />
              <Input
                label="Matrícula"
                placeholder="Matrícula"
                errorMessage={errorsFunc.funcionario?.matricula?.message}
                {...funcionario('funcionario.matricula')}
              />
              <Input
                label="RG"
                placeholder="RG"
                errorMessage={errorsFunc.funcionario?.rg?.message}
                {...funcionario('funcionario.rg')}
              />
              <Input
                label="Órgão expedidor"
                placeholder="Órgão expedidor"
                errorMessage={errorsFunc.funcionario?.orgao?.message}
                {...funcionario('funcionario.orgao')}
              />
              <Input
                label="UF Órgão expedidor"
                placeholder="UF Órgão expedidor"
                errorMessage={errorsFunc.funcionario?.orgaoUF?.message}
                {...funcionario('funcionario.orgaoUF')}
              />
              <Input
                label="CNS- Opcional"
                placeholder="CNS"
                errorMessage={errorsFunc.funcionario?.cns?.message}
                {...funcionario('funcionario.cns')}
              />
              <Select
                label="Gênero"
                options={[]}
                optionsFull={initialPlanData.listaSexos}
                placeholder="Gênero"
                errorMessage={errorsFunc.funcionario?.sexo?.message}
                {...funcionario('funcionario.sexo')}
              />
              <Select
                label="Estado civil"
                options={[]}
                optionsFull={initialPlanData.listaEstadosCivil}
                placeholder="Estado civil"
                errorMessage={errorsFunc.funcionario?.estCivil?.message}
                {...funcionario('funcionario.estCivil')}
              />
              <Input
                label="Celular"
                activateMask
                mask="(99) 99999-9999"
                placeholder="Telefone"
                errorMessage={errorsFunc.funcionario?.Celular?.message}
                {...funcionario('funcionario.Celular')}
              />
              <Input
                label="Telefone residencial"
                activateMask
                mask="(99) 9999-9999"
                placeholder="Telefone"
                errorMessage={errorsFunc.funcionario?.telefoneRes?.message}
                {...funcionario('funcionario.telefoneRes')}
              />
            </div>
            <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8 mt-16">
              Endereço do Funcionário
            </h2>
            <div className="grid grid-cols-2 gap-8">
              <Input
                activateMask
                mask="99999-999"
                label="CEP"
                className="w-full"
                //onBlur={e => getPlanValueByCEP(e.target.value)}
                placeholder="CEP"
                errorMessage={errorsFunc.funcionario?.address?.cep?.message}
                {...funcionario('funcionario.address.cep')}
              />
              <Input
                label="Número"
                //type="number"
                placeholder="Digite o seu número"
                errorMessage={errorsFunc.funcionario?.address?.number?.message}
                {...funcionario('funcionario.address.number')}
              />
            </div>
            <div className="grid min-[748px]:grid-cols-1 grid-cols-1 gap-6">
              <Input
                label="Logradouro"
                //value={street}
                placeholder="Digite o seu logradouro"
                errorMessage={errorsFunc.funcionario?.address?.street?.message}
                {...funcionario('funcionario.address.street')}
              />
              <div className="grid grid-cols-2 gap-8">
                <Input
                  label="Complemento"
                  placeholder="Digite o seu complemento"
                  errorMessage={
                    errorsFunc.funcionario?.address?.complement?.message
                  }
                  {...funcionario('funcionario.address.complement')}
                />
                <Input
                  label="Cidade"
                  //value={city}
                  placeholder="Digite sua cidade"
                  errorMessage={errorsFunc.funcionario?.address?.city?.message}
                  {...funcionario('funcionario.address.city')}
                />
              </div>
              <div className="grid grid-cols-3 gap-8">
                <Input
                  label="Bairro"
                  //value={neighborhood}
                  placeholder="Digite o seu bairro"
                  errorMessage={
                    errorsFunc.funcionario?.address?.neighborhood?.message
                  }
                  {...funcionario('funcionario.address.neighborhood')}
                />
                <Select
                  label="UF"
                  options={[]}
                  //options={data.listaUFS.map(uf => uf.sigla)}
                  optionsFull={initialPlanData.listaUFS}
                  //value={UF}
                  //onChange={{...register('payment.expireDate')}}
                  placeholder="UF"
                  errorMessage={errorsFunc.funcionario?.address?.uf?.message}
                  {...funcionario('funcionario.address.uf')}
                />
              </div>
            </div>
            {!isDependentFormActive && (
              <Button
                text="Adicionar dependente"
                intent="secondary"
                Icon={PlusIcon}
                className="mt-16"
                onClick={activateDependentForm}
              />
            )}
            {isDependentFormActive && (
              <DependentForm onSave={closeDependentForm} id={ind} />
            )}
            <div className="mt-10">
              {dependents?.map((dependent, ind) => {
                if (validateDependent(dependent) && ind < dependentCount) {
                  return (
                    <DependentCard
                      index={ind}
                      dependent={dependent as any}
                      key={dependent.nome}
                    />
                  );
                }
                return;
              })}
            </div>
          </form>
        )}
        {addFuncionary && (
          <Button
            type="button"
            onClick={addFuncionarioclick}
            text="Adicionar Funcionário"
          />
        )}
        {openForm && (
          <div
            style={{
              display: 'flex',
              marginTop: '25px',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              type="button"
              onClick={closeFuncionario}
              text="Cancelar"
              style={{ background: '#CC0505' }}
            />
            <Button
              type="button"
              intent="secondary"
              Icon={SaveIcon}
              onClick={addFuncionario}
              text="salvar Funcionário"
              style={{ marginRight: '20px' }}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            flexDirection: 'row-reverse',
          }}
        >
          <Button type="button" onClick={closeModal} text="Fechar" />
        </div>
      </Modal>
    </div>
  );
}

export default FunctionaryForm;
