import React, { ReactElement, useEffect, useState } from 'react';
import { GoForwardButton } from '../GoForwardButton';
import FunctionaryForm from './functionary/functionaryForm';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHireBussinessPlanForm } from '../../../contexts/HirePlanBussinessFormProvider';
import {
  useFunctionaryAddDataStore,
  useFunctionaryExcelDataStore,
  useFunctionaryOpenFormDataStore,
  useFunctionaryOpenModalDataStore,
} from '../../../stores/useFunctionaryStore';
import { useVidaCountStore } from '../../../stores/useQuantidadeVidas';
import { useRouteLoaderData } from 'react-router-dom';

export function FunctionaryRegisterForm(): ReactElement {
  const {
    register,
    handleSubmit,
    goToNextForm,
    errors,
    watch,
    setValue,
    setFuncValue,
  } = useHireBussinessPlanForm();
  const initialPlanData: any = useRouteLoaderData('token-empresa');
  const funcionarios =
    watch('user.funcionarios') != undefined ? watch('user.funcionarios') : [];
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  console.log('funcionarios: ', funcionarios);
  const toggleExpandRow = (index: number) => {
    setExpandedRow(prevIndex => (prevIndex === index ? null : index));
  };
  const setCountVidas = useVidaCountStore(x => x.setVidaCount);
  const Vidas = useVidaCountStore(x => x.count);

  const setFuncionaryOpenModal = useFunctionaryOpenModalDataStore(
    x => x.setfunctionaryOpenModalData,
  );
  const setOpenForm = useFunctionaryOpenFormDataStore(
    x => x.setfunctionaryOpenFormData,
  );
  const setAddFuncionary = useFunctionaryAddDataStore(
    x => x.setfunctionaryAddData,
  );
  const setAddExcel = useFunctionaryExcelDataStore(
    x => x.setfunctionaryExcelData,
  );

  useEffect(() => {
    const currentFuncionarios = watch('user.funcionarios') || [];
    const countdependentes = currentFuncionarios.reduce(
      (count, funcionario) => {
        return (
          count + (funcionario.dependentes ? funcionario.dependentes.length : 0)
        );
      },
      0,
    );
    const count = currentFuncionarios.length + countdependentes;
    setCountVidas(count);
  }, [watch('user.funcionarios')]);
  console.log('dados: ', initialPlanData);
  const editClick = (index: number) => {
    setFuncValue('funcionario', watch(`user.funcionarios.${index}`));
    setFuncionaryOpenModal(true);
    setOpenForm(true);
    setAddFuncionary(false);
    setAddExcel(false);
  };
  const [error, setError] = useState<string | null>(null);
  const removeClick = (ind: number) => {
    const currentFuncionarios = watch('user.funcionarios') || [];
    const updatedFuncionarios = currentFuncionarios.filter(
      (_, index) => index !== ind,
    );
    setValue('user.funcionarios', updatedFuncionarios);
  };

  const validaQTD = () => {
    if (
      Vidas >= initialPlanData.categoria.nu_vidas_min &&
      Vidas < initialPlanData.categoria.nu_vidas_max
    ) {
      setError(null);
      handleSubmit(goToNextForm)();
      return true;
    } else {
      setError(
        'Quantidade de vidas deve estar obrigatóriamente entre (' +
          initialPlanData.categoria.nu_vidas_min +
          ' e ' +
          initialPlanData.categoria.nu_vidas_max +
          ')',
      );
      return false;
    }
  };
  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <div style={{ marginBottom: '20px' }}>
        <FunctionaryForm id={funcionarios.length} />
      </div>

      <table width="100%">
        <thead
          style={{
            border: 'inset',
          }}
        >
          <tr>
            <th></th>
            <th style={{ textAlign: 'center' }}>Nome</th>
            <th style={{ textAlign: 'center' }}>CPF</th>
            <th style={{ textAlign: 'center' }}>Data de Nascimento</th>
            <th style={{ textAlign: 'center' }}>Sexo</th>
            <th style={{ textAlign: 'center' }}>Ações</th>
          </tr>
        </thead>
        <tbody
          style={{
            border: 'inset',
          }}
        >
          {funcionarios.map((funcionario, index) => (
            <React.Fragment key={index}>
              <tr
                onClick={() => toggleExpandRow(index)}
                style={{
                  cursor: 'pointer',
                  borderBottom: 'inset',
                  borderTop: 'inset',
                }}
              >
                <td>
                  {expandedRow === index ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <ArrowForwardIosIcon />
                  )}
                </td>
                <td style={{ textAlign: 'center' }}>{funcionario.nome}</td>
                <td style={{ textAlign: 'center' }}>{funcionario.cpf}</td>
                <td style={{ textAlign: 'center' }}>
                  {funcionario.dtNascimento}
                </td>
                <td style={{ textAlign: 'center' }}>{funcionario.sexo}</td>
                <td style={{ textAlign: 'center' }}>
                  <button type="button" onClick={() => editClick(index)}>
                    Editar
                  </button>
                  {' / '}
                  <button type="button" onClick={() => removeClick(index)}>
                    Remover
                  </button>
                </td>
              </tr>
              {expandedRow === index && funcionario.dependentes && (
                <tr>
                  <td colSpan={6}>
                    <strong style={{ margin: '2%' }}>Dependentes:</strong>
                    <table width="96%" style={{ margin: '2%' }}>
                      <thead
                        style={{
                          border: 'inset',
                        }}
                      >
                        <tr>
                          <th style={{ textAlign: 'center' }}>Nome</th>
                          <th style={{ textAlign: 'center' }}>CPF</th>
                          <th style={{ textAlign: 'center' }}>
                            Data de Nascimento
                          </th>
                          <th style={{ textAlign: 'center' }}>Parentesco</th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          border: 'inset',
                        }}
                      >
                        {funcionario.dependentes.map((dependente, depIndex) => (
                          <React.Fragment key={depIndex}>
                            <tr>
                              <td style={{ textAlign: 'center' }}>
                                {dependente.nome}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {dependente.cpf}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {dependente.dtNascimento}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {dependente.parentesco}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <GoForwardButton addBackButton validate={validaQTD} tipo="button" />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
}

export default FunctionaryRegisterForm;
