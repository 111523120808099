export enum IndividualWithoutShortageTokens {
  CLINICO = 'bhJfBDp', //714 inativo
  DOC = 'y0c6NN9', //789 inativo
  ORTO = 's501FqS', //712 inativo
  PROTESE = 'TlWnqLm', //795 inativo
}

export enum IndividualWithShortageTokens {
  CLINICO = 'LKKzNlc', //709
  CLINICONA = '5DtF7Uy', //948 NA
  DOC = 'b6Qgseq', //945 NA
  ORTO = 'MHUPnXO', //713
  PROTESE = 'N94uYbj', //796 NA
  KIDS = 'n5J4pNI', //937
  CLINICO_AN = 'p2wEboC', //989
  DOC_AN = 'VKDdNPP', //991 NA
  PROTESE_AN = 'ClYmJHs', //992 NA
}

export enum IndividualWithShortageNorthEastTokens {
  CLINICO = '1rtDA2U', //947
  CLINICO_AN = 'vyHGpJ2', //990
  DOC = 'b6Qgseq', //945 NA
  ORTO = 'jOPApmE', //946
  PROTESE = 'N94uYbj', //796 NA
  KIDS = 'n5J4pNI', //937
}

export enum BussinessTypeRangeLives1Tokens {
  CLINICO = 'oKq5BIe', //719
  DOC = 'JzzNwnl', //864
  ORTO = 'XnNj1rk', //720
}

export enum BussinessTypeRangeLives2Tokens {
  CLINICO = 'IsI7djS', //721
  DOC = 'rGUCEGO', //866
  ORTO = 'smjRgCfFD', //722
}

export enum Id_Products { // _NA = Nacional _NE = Nordeste _AN = Anual
  CLINICO_NA = 709,
  DOC_NA = 945,
  PROTESE_NA = 796,
  CLINICO_NA_AN = 989,
  DOC_NA_AN = 991,
  PROTESE_NA_AN = 992,
  CLINICO_NE = 947,
  CLINICO_NE_AN = 990,
}
