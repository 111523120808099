import React, { ReactElement, useState } from 'react';
import { Controller } from 'react-hook-form';
import Toggle from 'react-toggle';

import 'react-toggle/style.css';
import { useHireBussinessPlanForm } from '../../../contexts/HirePlanBussinessFormProvider';
import '../../../styles/toggle-styles.css';
import { Input } from '../../Input';
import { GoForwardButton } from '../GoForwardButton';
import { fileSchema } from './data/schema/functionarysSchema';

interface FileErrors {
  CNPJCard: string | null;
  CSMei: string | null;
  ComprovanteResi: string | null;
  Identidade: string | null;
  funcionario: [
    {
      erro: string | null;
      dependentes?: [
        {
          erro: string | null;
        },
      ];
    },
  ];
}

export function FileValidateForm(): ReactElement {
  const {
    watch,
    handleSubmit,
    goToNextForm,
    control,
    register,
    errors,
    unregister,
    setValue,
  } = useHireBussinessPlanForm();

  const funcionarios = watch('user.funcionarios');
  const files = watch('user');
  console.log(files);

  const [fileErrors, setFileErrors] = useState<FileErrors>({
    CNPJCard: null,
    CSMei: null,
    ComprovanteResi: null,
    Identidade: null,
    funcionario: [
      {
        erro: null,
        dependentes: [
          {
            erro: null,
          },
        ],
      },
    ],
  });

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileKey: number,
    index: number,
    depIndex: number,
  ) => {
    const files = event.target.files;
    const result = fileSchema.safeParse({ file: files });
    if (result.success === false) {
      switch (fileKey) {
        case 1: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            CNPJCard: result.error.issues[0].message,
          }));
          break;
        }
        case 2: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            CSMei: result.error.issues[0].message,
          }));
          break;
        }
        case 3: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            ComprovanteResi: result.error.issues[0].message,
          }));
          break;
        }
        case 4: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            Identidade: result.error.issues[0].message,
          }));
          break;
        }
        case 5: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            funcionario: {
              ...prevErrors.funcionario,
              [index]: {
                ...prevErrors.funcionario?.[index],
                erro: result.error.issues[0].message || null,
                dependentes: {
                  ...prevErrors.funcionario?.[index]?.dependentes,
                },
              },
            },
          }));
          break;
        }
        case 6: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            funcionario: {
              ...prevErrors.funcionario,
              [index]: {
                erro: prevErrors.funcionario?.[index]?.erro || null,
                dependentes: {
                  ...prevErrors.funcionario?.[index]?.dependentes,
                  [depIndex]: {
                    erro: result.error.issues[0].message || null,
                  },
                },
              },
            },
          }));
          break;
        }
      }
    } else {
      switch (fileKey) {
        case 1: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            CNPJCard: null,
          }));
          setValue('user.CNPJCard.file', files);
          break;
        }
        case 2: {
          console.log('entrou');
          setFileErrors(prevErrors => ({
            ...prevErrors,
            CSMei: null,
          }));
          setValue('user.CSMei.file', files);
          break;
        }
        case 3: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            ComprovanteResi: null,
          }));
          setValue('user.ComprovanteResi.file', files);
          break;
        }
        case 4: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            Identidade: null,
          }));
          setValue('user.Identidade.file', files);
          break;
        }
        case 5: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            funcionario: {
              ...prevErrors.funcionario,
              [index]: {
                ...prevErrors.funcionario?.[index],
                erro: null,
                dependentes: {
                  ...prevErrors.funcionario?.[index]?.dependentes,
                },
              },
            },
          }));
          setValue(`user.funcionarios.${index}.vinculo.file`, files);
          break;
        }
        case 6: {
          setFileErrors(prevErrors => ({
            ...prevErrors,
            funcionario: {
              ...prevErrors.funcionario,
              [index]: {
                ...prevErrors.funcionario?.[index],
                dependentes: {
                  ...prevErrors.funcionario?.[index]?.dependentes,
                  [depIndex]: {
                    erro: null,
                  },
                },
              },
            },
          }));
          setValue(
            `user.funcionarios.${index}.dependentes.${depIndex}.vinculo.file`,
            files,
          );
          break;
        }
      }
    }
    console.log('erro: ', fileErrors);
  };

  const validateFiles = () => {
    let error = true;
    const newFileErrors = { ...fileErrors };

    // Verifica se as chaves já existem, se não, inicializa-as corretamente
    if (!newFileErrors.funcionario) {
      newFileErrors.funcionario = [{ erro: null }];
    }

    if (!files.CNPJCard?.file) {
      newFileErrors.CNPJCard = 'Cartão CNPJ é obrigatório.';
      error = false;
    }
    if (!files.CSMei?.file) {
      newFileErrors.CSMei = 'Contrato Social/Certificado MEI é obrigatório.';
      error = false;
    }
    if (!files.ComprovanteResi?.file) {
      newFileErrors.ComprovanteResi =
        'Comprovante de residência é obrigatório.';
      error = false;
    }
    if (!files.Identidade?.file) {
      newFileErrors.Identidade = 'Documento de identidade é obrigatório.';
      error = false;
    }

    funcionarios.forEach((funcionario, index) => {
      // Inicializa newFileErrors.funcionario[index] se não existir
      if (!newFileErrors.funcionario[index]) {
        newFileErrors.funcionario[index] = {
          erro: null,
          dependentes: [{ erro: null }],
        };
      }

      if (!files.funcionarios?.[index]?.vinculo?.file) {
        newFileErrors.funcionario[index].erro =
          'Comprovante de vínculo é obrigatório.';
        error = false;
      }

      // Verifica se dependentes estão presentes
      funcionario.dependentes?.forEach((dependente, depIndex) => {
        // Inicializa dependentes se necessário
        if (!newFileErrors.funcionario[index].dependentes[depIndex]) {
          newFileErrors.funcionario[index].dependentes[depIndex] = {
            erro: null,
          };
        }

        if (
          !files.funcionarios?.[index]?.dependentes?.[depIndex]?.vinculo?.file
        ) {
          newFileErrors.funcionario[index].dependentes[depIndex].erro =
            'Comprovante de vínculo com o titular é obrigatório.';
          error = false;
        }
      });
    });

    setFileErrors(newFileErrors);
    if (error) {
      handleSubmit(goToNextForm)();
    }
    return error;
  };

  return (
    <form
      className="max-w-[755px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="max-[748px]:hidden text-[2rem] text-primary-main font-semibold mb-8 text-center">
        Inclusão dos arquivos necessários
      </h2>
      <div>
        <h3 className="text-2xl max-[1300px]:text-lg text-high-emphasis font-normal mb-5">
          Arquivos da Empresa
        </h3>
        <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-8">
          <label>Cartão Cnpj:</label>
          <Input
            label=""
            type="file"
            errorMessage={fileErrors.CNPJCard}
            onChange={e => handleFileChange(e, 1, 0, 0)}
          />
          <label>Contrato Social / Certificado Mei:</label>
          <Input
            label=""
            type="file"
            errorMessage={fileErrors.CSMei}
            onChange={e => handleFileChange(e, 2, 0, 0)}
          />
          <label>Comprovante de endereço:</label>
          <Input
            label=""
            type="file"
            errorMessage={fileErrors.ComprovanteResi}
            onChange={e => handleFileChange(e, 3, 0, 0)}
          />
          <label>Identidade valida com Cpf:</label>
          <Input
            label=""
            type="file"
            errorMessage={fileErrors.Identidade}
            onChange={e => handleFileChange(e, 4, 0, 0)}
          />
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <h3 className="text-2xl max-[1300px]:text-lg text-high-emphasis font-normal mb-5">
          Arquivos de comprovação de vínculo
        </h3>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Comprovante do vínculo</th>
            </tr>
          </thead>
          <tbody>
            {funcionarios.map((funcionario, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{funcionario.nome}</td>
                  <td>
                    <label>
                      Comprovante de Vínculo com a Empresa (contracheque):
                    </label>
                    <Input
                      label=""
                      type="file"
                      errorMessage={fileErrors.funcionario[index]?.erro || null}
                      onChange={e => handleFileChange(e, 5, index, 0)}
                    />
                  </td>
                </tr>

                {funcionario.dependentes.map((dependente, depIndex) => (
                  <tr key={`${index}-${depIndex}`}>
                    <td>{dependente.nome}</td>
                    <td>
                      <Input
                        label="Comprovante de Vínculo com o Titular (certidão de Nascimento,RG...) "
                        type="file"
                        errorMessage={
                          fileErrors.funcionario?.[index]?.dependentes?.[
                            depIndex
                          ]?.erro
                        }
                        onChange={e => handleFileChange(e, 6, index, depIndex)}
                      />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <GoForwardButton addBackButton validate={validateFiles} tipo="button" />
    </form>
  );
}
