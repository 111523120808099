import { ReactElement } from 'react';

import { useHirePlanFormStepStore } from '../../stores/useHirePlanFormStepStore';
import { BussinessRegisterForm } from './Bussiness-steps/BussinessRegisterForm';
import FunctionaryRegisterForm from './Bussiness-steps/FunctionaryRegisterForm';
import { FileValidateForm } from './Bussiness-steps/FilesValidateForm';
import { BussinessPaymentValidate } from './Bussiness-steps/BussinessPaymentValidate';

export function HireBussinessPlanFormStepHandler(): ReactElement {
  const step = useHirePlanFormStepStore(state => state.step);
  console.log('step: ', step);
  switch (step) {
    case 1: {
      return <BussinessRegisterForm />;
    }

    case 2: {
      return <FunctionaryRegisterForm />;
    }

    case 3: {
      return <FileValidateForm />;
    }

    case 4: {
      return <BussinessPaymentValidate />;
    }

    default: {
      return <BussinessRegisterForm />;
    }
  }
  /* return (
    <section>
      <div>
        <div>
          <CompanyForm />
        </div>
      </div>
    </section>
  ); */
}
