import { ReactElement, useEffect, useState } from 'react';

import { useHirePlanForm } from '../../../contexts/HirePlanFormProvider';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { GoForwardButton } from '../GoForwardButton';
import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';
import { useRouteLoaderData } from 'react-router-dom';
import { PlanBoost } from '../../../utils/Enums/PlanBoostEnum';
import {
  Id_Products,
  IndividualWithShortageNorthEastTokens,
  IndividualWithShortageTokens,
  IndividualWithoutShortageTokens,
} from '../../../utils/Enums/Tokens';
import { getPlanDetails } from '../../../requests/requests';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';
import { useHireBussinessPlanForm } from '../../../contexts/HirePlanBussinessFormProvider';
import { PaymentSelector } from '../steps/PaymentMethodForm/PaymentSelector';
import { useLoadingStore } from '../../../stores/useLoadingStore';
import { useError } from '../../../stores/useError';
import { ErrorModal } from '../../Modal/Modal';
import { FinancialResponsibleForm } from '../steps/FinancialResponsibleForm';
import Toggle from 'react-toggle';
import { CartIcon } from '../../icons/CartIcon';
import Loading from '../../icons/Loading';
import { format } from 'date-fns';

export function BussinessPaymentValidate(): ReactElement {
  const { register, handleSubmit, onSubmit, goToNextForm, errors } =
    useHireBussinessPlanForm();
  const individualPlanData: any = useRouteLoaderData('token-empresa');
  const boostedPlanData = useBoostedPlanDataStore(state => state.data);
  const [data, setData] = useState(individualPlanData);
  const width = useWindowSizeStore(state => state.width);
  const setBoostPlanData = useBoostedPlanDataStore(
    state => state.setBoostPlanData,
  );
  const loading = useLoadingStore(state => state.loading);
  const error = useError(state => state.error);
  const title = useError(state => state.title);
  const text = useError(state => state.text);

  const planInitial =
    boostedPlanData == undefined
      ? data?.produtoComercial?.id_prodcomerc
      : boostedPlanData.produtoComercial?.id_prodcomerc;
  const [plano, setPlano] = useState(planInitial);
  console.log('boostedPlanData: ', boostedPlanData);
  console.log('planData: ', data);
  useEffect(() => {
    if (boostedPlanData != undefined) {
      setData(boostedPlanData);
      setPlano(boostedPlanData.produtoComercial?.id_prodcomerc);
    }
  }, [boostedPlanData]);
  useEffect(() => {
    setBoostPlanData(individualPlanData);
  }, []);

  return (
    <form
      className="flex flex-col items-center max-w-[755px] w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && <ErrorModal open={true} title={title} text={text} />}
      {width >= 748 && (
        <>
          <h2 className="text-[2rem] font-semibold text-primary-main mt-12">
            Informação de Faturamento
          </h2>
          <h3 className="text-2xl max-[1300px]:text-lg text-high-emphasis font-normal mb-5">
            Selecione aqui a data de pagamento do boleto do primeiro faturamento
          </h3>
          <div className="grid grid-cols-1 gap-8">
            <Select
              label="Selecione a data de vencimento"
              options={data.vencimentoBoletos.map(boleto =>
                format(new Date(boleto.select), 'dd/MM/yyyy'),
              )}
              placeholder="Data de vencimento"
              errorMessage={errors.payment?.billetDate?.message}
              {...register('payment.billetDate')}
            />
          </div>
          <h2 className="text-[2rem] font-semibold text-primary-main mt-12">
            Informação de Patrocínio
          </h2>
          <h3 className="text-2xl max-[1300px]:text-lg text-high-emphasis font-normal mb-5">
            A pessoa jurídica contratante:
          </h3>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value="1"
                  //onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.patrocinio')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Patrocinará no todo, o valor da contraprestação para os
                beneficiários titulares.
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value="2"
                  //onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.patrocinio')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Patrocinará, em parte, o valor da contraprestação para os
                beneficiários titulares.
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value="1"
                  //onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.patrocinio')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Não Patrocinará o valor da contraprestação para os beneficiários
                titulares.
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex items-start border bg-primary-container2 rounded-md w-full py-6 px-4 mt-6">
            <div className="grid grid-cols-1 gap-8">
              <Input
                className="w-[20px] h-[20px]"
                label=""
                type="checkbox"
                errorMessage={errors.payment?.aceite?.message}
                {...register('payment.aceite')}
              />
            </div>
            <span className="flex-1 text-neutral-dark text-sm ml-4">
              Confimo as informações acima e estou ciente
            </span>
          </div>
        </>
      )}
      <GoForwardButton
        disabled={loading}
        addBackButton
        Icon={loading ? Loading : CartIcon}
        desktopText={loading ? '' : 'Finalizar compra'}
        mobileText={loading ? '' : 'Finalizar compra'}
      />
    </form>
  );
}
