import { ReactElement } from 'react';

import { useHireBussinessPlanForm } from '../../../../contexts/HirePlanBussinessFormProvider';
import { useDependentCountStore } from '../../../../stores/useDependentCountStore';
import { Button } from '../../../Button';
import { CloudIcon } from '../../../icons/CloudIcon';
import { SaveIcon } from '../../../icons/SaveIcon';
import { Input } from '../../../Input';
import { Select } from '../../../Select';
import { useRouteLoaderData } from 'react-router-dom';
import {
  changeCivilStatus,
  changeGender,
  changeIssuingAgency,
  changeParentage,
  changeUF,
} from '../../../../utils/ChangeArrays';

interface Props {
  onSave: () => void;
  id: number | null;
}

export function DependentForm({ onSave, id }: Props): ReactElement {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    funcionario,
    watchFunc,
    setFuncValue,
    errorsFunc,
  } = useHireBussinessPlanForm();
  const data: any = useRouteLoaderData('token-empresa');

  const dependentCount = useDependentCountStore(state => state.dependentCount);
  const addDependent = useDependentCountStore(state => state.addDependent);
  console.log('dependente: ', dependentCount);
  function addDependente() {
    /* setFuncValue(`funcionario.dependentes.${dependentCount + 1}.nome`, '');
    setFuncValue(
      `funcionario.dependentes.${dependentCount + 1}.dtNascimento`,
      '',
    );
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.estCivil`, '');
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.sexo`, '');
    setFuncValue(
      `funcionario.dependentes.${dependentCount + 1}.parentesco`,
      '',
    );
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.cpf`, '');
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.rg`, '');
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.orgao`, '');
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.orgaoUF`, '');
    setFuncValue(`funcionario.dependentes.${dependentCount + 1}.cns`, '');
    setFuncValue(
      `funcionario.dependentes.${dependentCount + 1}.motherName`,
      '',
    ); */
    addDependent();
    onSave();
  }

  changeUF(data.listaUFS);
  changeGender(data.listaSexos);
  changeIssuingAgency(data.listaOrgaosExpedidor);
  changeCivilStatus(data.listaEstadosCivil);
  changeParentage(data.listaParentescos);

  return (
    <form
      className="border border-[#DFE2F2] w-full min-[748px]:mt-16 rounded-xl p-6"
      //onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-8">
        <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
          Dados do Dependente
        </h2>
        <div className="grid min-[748px]:grid-cols-3 gap-8">
          <div className="min-[748px]:col-span-2">
            <Input
              label="Nome"
              placeholder="Nome"
              errorMessage={
                errorsFunc?.funcionario?.dependentes?.[dependentCount]?.nome
                  ?.message
              }
              {...funcionario(
                `funcionario.dependentes.${dependentCount + 1}.nome`,
              )}
            />
          </div>
          <Input
            activateMask
            mask="99/99/9999"
            label="Data de nascimento"
            placeholder="dd/mm/aaaa"
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]
                ?.dtNascimento?.message
            }
            {...funcionario(
              `funcionario.dependentes.${dependentCount + 1}.dtNascimento`,
            )}
          />
        </div>
        <div className="grid min-[748px]:grid-cols-3 gap-8">
          <Select
            label="Estado civil"
            placeholder="Estado civil"
            options={[]}
            optionsFull={data.listaEstadosCivil}
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]?.estCivil
                ?.message
            }
            {...funcionario(
              `funcionario.dependentes.${dependentCount + 1}.estCivil`,
            )}
          />
          <Select
            label="Gênero"
            placeholder="Gênero"
            options={[]}
            optionsFull={data.listaSexos}
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]?.sexo
                ?.message
            }
            {...funcionario(
              `funcionario.dependentes.${dependentCount + 1}.sexo`,
            )}
          />
          <Select
            label="Parentesco"
            placeholder="Parentesco"
            options={[]}
            optionsFull={data.listaParentescos}
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]?.parentesco
                ?.message
            }
            {...funcionario(
              `funcionario.dependentes.${dependentCount + 1}.parentesco`,
            )}
          />
        </div>
        <div className="grid min-[748px]:grid-cols-2 gap-8">
          <Input
            activateMask
            mask="999.999.999-99"
            label="CPF"
            placeholder="CPF"
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]?.cpf
                ?.message
            }
            {...funcionario(
              `funcionario.dependentes.${dependentCount + 1}.cpf`,
            )}
          />
          <Input
            label="RG"
            placeholder="RG"
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]?.rg
                ?.message
            }
            {...funcionario(`funcionario.dependentes.${dependentCount + 1}.rg`)}
          />
        </div>
        <div className="grid min-[748px]:grid-cols-3 gap-8">
          <div className="grid min-[748px]:grid-cols-2 col-span-2 max-[748px]:grid-cols-1 gap-8">
            <Select
              label="Órgão expeditor"
              placeholder="Órgão"
              options={[]}
              optionsFull={data.listaOrgaosExpedidor}
              errorMessage={
                errorsFunc?.funcionario?.dependentes?.[dependentCount]?.orgao
                  ?.message
              }
              {...funcionario(
                `funcionario.dependentes.${dependentCount + 1}.orgao`,
              )}
            />
            <Select
              label="UF do órgão expeditor"
              placeholder="UF do órgão"
              options={[]}
              optionsFull={data.listaUFS}
              errorMessage={
                errorsFunc?.funcionario?.dependentes?.[dependentCount]?.orgaoUF
                  ?.message
              }
              {...funcionario(
                `funcionario.dependentes.${dependentCount + 1}.orgaoUF`,
              )}
            />
          </div>
          <div className="max-[748px]:col-span-2">
            <Input
              label="CNS"
              placeholder="CNS"
              errorMessage={
                errorsFunc?.funcionario?.dependentes?.[dependentCount]?.cns
                  ?.message
              }
              {...funcionario(
                `funcionario.dependentes.${dependentCount + 1}.cns`,
              )}
            />
          </div>
        </div>
        <div className="grid grid-cols-1">
          <Input
            label="Nome da mãe"
            placeholder="Nome da mãe"
            errorMessage={
              errorsFunc?.funcionario?.dependentes?.[dependentCount]?.motherName
                ?.message
            }
            {...funcionario(
              `funcionario.dependentes.${dependentCount + 1}.motherName`,
            )}
          />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Button
          type="button"
          text="Salvar dependente"
          intent="secondary"
          Icon={SaveIcon}
          onClick={addDependente}
        />
      </div>
    </form>
  );
}
